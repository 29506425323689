@if (type === 'globe') {
  <a
    tooltipPosition="bottom"
    class="cursor-pointer px-3 py-2 md:p-4 md:flex items-center gap-2 rounded-full mx-2 header-lang-selector"
    pTooltip="{{ 'Languages' | translate }}"
    (click)="langChangeMenu()">
    <i class="pi pi-globe"></i>
    <span class="text-sm font-medium text-gray-400 uppercase hidden lg:block">{{ usedLang }}</span>
  </a>
}
@if (type === 'flag') {
  <a
    tooltipPosition="bottom"
    class="flex p-3 lg:px-3 lg:py-2 items-center cursor-pointer"
    [appendTo]="'body'"
    pTooltip="{{ 'Languages' | translate }}"
    (click)="langChangeMenu()">
    <img
      src="/assets/images/blocks/flag/flag_placeholder.png"
      style="width: 28px"
      [class]="'mr-2 flag flag-s-' + usedLang" />
  </a>
}
